import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto my-10 p-8 bg-white shadow-lg rounded-lg">
      <h1 className="text-4xl font-bold mb-6 text-gray-800">Privacy Policy for Tarz - Encloy's Digital Assistant</h1>
      <p className="text-sm text-gray-600 mb-6">Effective Date: 2024-02-01</p>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-2 text-indigo-700">Information We Collect:</h2>
        <p className="text-gray-800">
          <strong>Personal Information:</strong> We collect the following personal information from you when you use Tarz:
        </p>
        <ul className="list-disc pl-6 text-gray-800">
          <li>Name</li>
          <li>Email address</li>
          <li>Content of your chat with Tarz (excluding sensitive information like health, financial data, or religious beliefs)</li>
        </ul>
        <p className="text-gray-800 mt-4">
          <strong>Non-Personal Information:</strong> We also collect non-personal information, such as:
        </p>
        <ul className="list-disc pl-6 text-gray-800">
          <li>Your IP address</li>
          <li>Browser type and operating system</li>
          <li>Date and time of your visit</li>
          <li>Pages you visit on our website</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-2 text-indigo-700">How We Use Your Information:</h2>
        <p className="text-gray-800">
          We use your personal information to:
        </p>
        <ul className="list-disc pl-6 text-gray-800">
          <li>Identify you and verify your identity.</li>
          <li>Respond to your inquiries and requests.</li>
          <li>Schedule meetings with our employees.</li>
          <li>Improve Tarz and provide you with a better user experience.</li>
          <li>Analyze website traffic and user behavior.</li>
          <li>Comply with legal and regulatory requirements.</li>
        </ul>
        <p className="text-gray-800 mt-4">
          We use your non-personal information to:
        </p>
        <ul className="list-disc pl-6 text-gray-800">
          <li>Improve the performance of our website and Tarz.</li>
          <li>Understand how users interact with Tarz.</li>
          <li>Generate reports and insights for internal purposes.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-2 text-indigo-700">Sharing Your Information:</h2>
        <p className="text-gray-800">
          We may share your personal information with third-party service providers who help us operate Tarz, such as Google Calendar API for scheduling meetings.
          We may share your non-personal information with third-party analytics providers.
          We will not share your personal information with any other third parties without your consent, except as required by law or to protect our rights and interests.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-2 text-indigo-700">Data Retention:</h2>
        <p className="text-gray-800">
          We will retain your personal information for as long as necessary to provide you with the services you request and to comply with legal and regulatory requirements.
          We will delete your personal information when it is no longer necessary.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-2 text-indigo-700">Your Choices:</h2>
        <p className="text-gray-800">
          You can access, update, or delete your personal information by contacting us at [your email address].
          You can opt out of the collection of your non-personal information by using a browser extension or setting your browser to block cookies.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-2 text-indigo-700">Security:</h2>
        <p className="text-gray-800">
          We take reasonable measures to protect your personal information from unauthorized access, use, disclosure, alteration, or destruction.
          However, no website or internet transmission is completely secure. We cannot guarantee the security of your personal information.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-2 text-indigo-700">Changes to this Privacy Policy:</h2>
        <p className="text-gray-800">
          We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. You are advised to review the Privacy Policy periodically for any changes.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-2 text-indigo-700">Contact Us:</h2>
        <p className="text-gray-800">
          If you have any questions about this Privacy Policy, please contact us at hello@encloy.com.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

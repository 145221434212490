// ChatWindow.js
import React, { useState, useRef, useEffect } from 'react';
import { startChat, sendMessage } from './GenerativeAI';
import ChatBubble from './chatbubble';

const ChatWindow = () => {
  const [userInput, setUserInput] = useState('');
  const [chat, setChat] = useState(startChat());
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const chatWindowRef = useRef(null);

  const handleSendMessage = async () => {
    const userMessage = { role: 'user', parts: [{ text: userInput }] };
    setMessages(prevMessages => [...prevMessages, userMessage]);

    setIsLoading(true);
    setUserInput('');
    const botResponse = await sendMessage(chat, userInput);
    const modelMessage = { role: 'model', parts: [{ text: botResponse }] };
    setMessages(prevMessages => [...prevMessages, modelMessage]);
    setIsLoading(false);

    
  };

  // Show the initial AI message when the component mounts
  useEffect(() => {
    const initialAIMessage = { role: 'model', parts: [{ text: "Hello there! 👋 I'm Tarz, your friendly digital assistant from Encloy! Before we dive into the exciting world of creativity, may I know your name and email address, please?" }] };
    setMessages([initialAIMessage]);
  }, []);

  // Scroll to the bottom of the chat window when new messages arrive
  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 overflow-hidden bg-gray-200  dark:bg-gray-800">
      <div className="max-h-screen overflow-y-auto p-10 mb-16" style={{ paddingBottom: '80px' }} ref={chatWindowRef}>
        {messages.map((message, index) => (
          <ChatBubble
            key={index}
            senderName={message.role === 'model' ? 'Tarz' : 'You'}
            message={message.parts[0].text}
            isUser={message.role === 'user'}
          />
        ))}
        <div className='mt-2'></div>
        {isLoading && (
          
            <div className="px-3 py-1 text-xs font-medium leading-none text-left text-blue-800  rounded-full animate-pulse  dark:text-blue-200">thinking...</div>
          
        )}
      </div>
      <div className='mt-2'></div>
      <div className="fixed bottom-0 left-0 right-0 p-4 bg-gray-200  dark:bg-gray-800 flex items-center">
        <input
          type="text"
          id="base-input"
          className="flex-grow bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          placeholder="Type your message..."
        />
        <button
          onClick={handleSendMessage}
          type="button"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 ml-2 sm:ml-4 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9" />
          </svg>
          <span className="sr-only">Send</span>
        </button>
      </div>
    </div>
  );
};

export default ChatWindow;

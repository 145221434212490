// ChatBubble.js
import React from 'react';

const ChatBubble = ({ senderName, message, isUser }) => {
  const bubbleColor = isUser ? 'bg-blue-500 text-white' : 'bg-lightBlue-300  text-gray-900 dark:text-white';

  const boldRegex = /\*\*(.*?)\*\*/g;
  const formattedMessage = message.split(boldRegex).map((part, index) => {
    if (index % 2 === 1) {
      // Odd index means the part is between double asterisks, apply bold styling
      return <span key={index} className="font-bold">{part}</span>;
    }
    return part;
  });

  return (
    <div className={`flex ${isUser ? 'items-end' : 'items-start'} gap-2.5`}>
      <div className={`flex flex-col w-full max-w-[320px] leading-1.5 ${isUser ? 'ml-auto' : ''}`}>
        <div className="flex items-center space-x-2 rtl:space-x-reverse">
          <span className={`text-sm font-semibold ${isUser ? 'text-blue-500' : 'text-gray-900 dark:text-white'}`}>{senderName}</span>
        </div>
        <div className={`flex flex-col leading-1.5 p-4 ${bubbleColor} rounded-e-xl rounded-es-xl dark:bg-lightBlue-300 backdrop-blur-md backdrop-filter`}>
          <p className="text-sm font-normal py-2">{formattedMessage}</p>
        </div>
      </div>
    </div>
  );
};

export default ChatBubble;
